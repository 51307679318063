
import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Ads300x250 from './Ads300x250'; 
import Adshorizontal from './Adshorizontal';
class   Style4 extends React.Component {
  constructor(){
    super();
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],
        news3 : [],
        content:[]
        
        
    }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit=4&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
})
.then(res=>{
  this.setState({news0:res.data['message'][0]})
  this.setState({news1:res.data['message'][1]})
  this.setState({news2:res.data['message'][2]})
  this.setState({news3:res.data['message'][3]})
  var stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content:stripedHtml.slice(0,200)})
  //console.log(res.data['data'][0])
})

.catch(
  err =>{ console.log(err)}
)
}
  render() {
return <div>
  <div className="row charity">
          <div className="col-sm-6">
            <div className="news">
            <h2 className={'l-title l-title--'+this.props.class}><span>{this.props.head}</span></h2>
              <div className="news__item d_block_res"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.name}>{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=230&h=265'}alt="Loading..." title="Loading..." /> */}
              <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="styl4-char-img" width="230px" height="265px"  alt="Loading..." title="Loading..."/></Link>
                <div className="l-details news-dtls4">
                  <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}>{this.state.news0['title']} </Link></h3>
                  <div>{this.state.content}...</div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-sm-6 mt-5">

                 <Ads300x250 id={this.props.ads}/>

          </div>

          
          <div className="col-sm-4 mt-5">
            <ul className="news__list">
              <li className="news__item">
                <div className="l-details news-dtls3">
                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news1['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news1['start_date']}</Moment></div>
                  <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}> <ReactHtml html={this.state.news1['title']}/></Link></div>
                </div>
              </li>
              <li className="news__item">
                <div className="l-details news-dtls3">
                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news2['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                  <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}> <ReactHtml html={this.state.news2['title']}/></Link></div>
                </div>
              </li>
              <li className="news__item">
                <div className="l-details news-dtls3">
                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news3['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news3['start_date']}</Moment></div>
                  <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3['name']}> <ReactHtml html={this.state.news3['title']}/></Link></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
         <div className="row">
                  <div className="col-sm-16 d-flex justify-content-center">
                          <Adshorizontal id={this.props.bottomAds}/>
                  </div>
        </div>
</div>
}
}
export default Style4;