
import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
class   Disclaimer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          
            about:[]
            
        }
    }
    componentDidMount(){
       // console.log(process.env.REACT_APP_API_URL +'api/resource/Web Page/'+this.props.url)
      axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/resource/Web Page/'+this.props.url), {
        headers : {'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({about:response.data['data']})
        //console.log(response.data['data'])
      
     
    })
    .catch(
        err =>{ console.log(err)}
      )
    }
      render() {
return <div>
    <div className="container">
        <div className="row">
            <div className="col-md-16 ml-4">
            <h2>{this.props.head}</h2>
            <p className="ml-4"> <ReactHtml html={this.state.about['main_section_html']}/>...</p>
          
            </div>
        </div>

</div>
</div>
}
}
export default Disclaimer;
                
                
                
                
                

                
                
                
                
                
         
         
         
         
         
         
         
         

                 
            
            
            
            
            
       
