import React from 'react';
import axios from 'axios';
class Ads300x600 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        ad : []
    }
}
async componentDidMount(){
  if(this.props.id){
      axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getAd?zone='+this.props.id), {
        headers : {'content-type': 'application/json'}
      })
      .then(
          res=>{
            if(res.data['message']){
              this.setState({ad:res.data['message']})
             // console.log(this.props.id)
            //  console.log(res.data['message'])
            }
            
      })
      .catch(
          err =>{ console.log(err)}
        )
  }
}
  render() {
return <div>
      {this.state.ad.map((itm,k)=>{
             
            return(
              <div key={k} className="l-ad-wrap"><a target="_blank"  rel="noreferrer"  href={itm.redirect_url}><img src={process.env.REACT_APP_API_URL+itm.banner_image} alt="Ad" title="Ad"/></a>
            </div>)
                })}

     
            
</div>
}
}
export default Ads300x600;