import React from 'react';

import axios from 'axios';

import Ads300x250 from '../Components/Ads300x250';
class Mostread extends React.Component {
    constructor(){
        super();
        this.state = {
           title: [],  title1: [],  title2: [],  title3: [],  title4: [],
          content: [],content1: [],content2: [],content3: [],content4: [],
          image: [],image1: [],image2: [],image3: [],image4: [],
          rec:[], today:[], month:[]
        }
    }
    openCity(e, cityName){
        //e.preventDefault();
      var i;
      var x = document.getElementsByClassName("city");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";  
      }
      document.getElementById(cityName).style.display = "block";  
    }
    async componentDidMount(){
        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.mostReadWeek'), {
            headers : {'content-type': 'application/json'}
                    })
                    .then(response=>{
                      this.setState({rec:response.data['message']})
                     // console.log(response.data['data'])
                  })
        
        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.mostReadToday'), {
          headers : {'content-type': 'application/json'}
                  })
                  .then(response=>{
                    this.setState({today:response.data['message']})
                    // console.log(response.data['data'])
                })

        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.mostRead'), {
          headers : {'content-type': 'application/json'}
                  })
                  .then(response=>{
                    this.setState({month:response.data['message']})
                    // console.log(response.data['data'])
                })
    }
                render() {
    return <>
    <div className="mt-5">

<Ads300x250 id="ZNE020" />

</div>
    <div className="most-read">

     <h3 className="l-title"><span> Most Read</span></h3>
    <div className="w3-bar w3-black">
  <button className="w3-bar-item w3-button" onClick={(e) => { this.openCity(e, 'London');}} >Today</button>
  <button className="w3-bar-item w3-button" onClick={(e) => { this.openCity(e, 'Paris');}}>Last Week</button>
  <button className="w3-bar-item w3-button" onClick={(e) => { this.openCity(e, 'Tokyo');}}>Last Month</button>
</div>
                <div className="">
                <div id="London" className="city">
                  <ul className="latest-news__list">
                    {this.state.today.map((itm,k)=>{
                        return(
                      
                        <li key={k} className="news__item">
                           
                      <div className="l-details">
                        <a href={itm.name}>{itm.title}</a>
                        
                      </div>
                    </li>
  )
                                                            })} 
                    </ul>
                </div>

                <div id="Paris" className="city" style={{display: "none"}}>
                  
                  <ul className="latest-news__list">
                    {this.state.rec.map((itm,k)=>{
                                            return(
                      
                        <li key={k} className="news__item">
                            
                            
                      <div className="l-details">
                        <a href={itm.name}>{itm.title}</a>
                        
                      </div>
                    </li>
  )
                                                            })} 
                    </ul>
                </div>

                <div id="Tokyo" className="city" style={{display: "none"}}>
                <ul className="latest-news__list">
                    {this.state.month.map((itm,k)=>{
                                            return(
                      
                        <li key={k} className="news__item">
                            
                            
                      <div className="l-details">
                        <a href={itm.name}>{itm.title}</a>
                        
                      </div>
                    </li>
  )
                                                            })} 
                    </ul>
                </div>
                   
                    
                     
                </div>
                    

    </div>

    </>
     }
    }
    export default Mostread;
                    