import React from 'react';
import Ads300x90 from '../Components/Ads300x90';
import axios from 'axios';
class Advsmall extends React.Component {
  constructor(){
      super();
      this.state = {
        Img: [],
        rate:undefined
      }


  }

async componentDidMount(){
     //https://desk.britishmalayali.co.uk/api/method/bm.api.getXrate

     axios.get(encodeURI(process.env.REACT_APP_API_URL+'/api/method/bm.api.getXrate'), {
                headers : {'content-type': 'application/json'}
            })
            .then(res=>{ this.setState({rate:res.data['message']})         
            })
            .catch(
                err =>{ console.log(err)}
              )
}








  render() {
return <div>
            <div className="header-ads">
              <div className="header-ads__item "><div className=" "><img src={process.env.REACT_APP_API_URL + 'files/5.gif'} alt="Advertisement 4" title="Advertisement 4"/></div>
                  <div className="header-right-first-inner2 exchg  ">
                        <p className="text-center "><b>1 GBP = {this.state.rate} INR</b></p>
                  </div>
              </div>
              <Ads300x90 id="ZNE002"/>
              <Ads300x90 id="ZNE003"/>
            </div>
</div>
}
}
export default Advsmall;