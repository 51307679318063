import React from 'react';
import { Link } from 'react-router-dom';

class SubBanner extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        banner : [],
        newsid: '',
    }
  }
  componentDidMount(){
    if(this.props.banner['news_link'])
    {
        let newsID = this.props.banner['news_link'].split("/");
        this.setState({newsid:"news/" + newsID[newsID.length-1]});
    }
   
  }


  render() {
   if(this.props.banner){
    
    return <div>
        { this.props.banner.sub_enabled == '1' ? 
        <div className="row mb-4">
          <div className="col-16">
            <Link to={this.props.banner['news_category']?this.props.banner['category_route']:this.state.newsid}className="mainnav__link" style={{width:"100%"}}>
              <img alt ="Big Story" src={process.env.REACT_APP_API_URL+this.props.banner['banner_image']} style={{width:"100%"}} />
            </Link>
          </div>
        </div>
        : '' }
    </div>
   }
   else{
     return <> </>
   }

}
}
export default SubBanner;

