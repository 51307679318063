import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
class   Aboutus extends React.Component {
    constructor(){
        super();
        this.state = {
          
            about:[]
            
        }
    }
    componentDidMount(){
      axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/resource/Web Page/about-us'), {
        headers : {'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({about:response.data['data']})
        //console.log(response.data['data'])
      
     
    })
    .catch(
        err =>{ console.log(err)}
      )
    }
      render() {
        return <div>
<div className="container">
        <div className="row">
            <div className="col-md-16 ml-4">
            <h2>About Us</h2>
            <p className="ml-4"> <ReactHtml html={this.state.about['main_section_html']}/>...</p>
            <p className="ml-4"> <ReactHtml html={this.state.about['main_section_html']}/>...</p>

            </div>
        </div>

</div>



        </div>
        }
    }
    export default Aboutus;