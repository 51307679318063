


import React from 'react';
import axios from 'axios';
import Listitems from '../Components/Listitems';
class Group extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        news : []
    }
}
getData(){
  //alert(this.props.cate)
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.subMenu?parentRoute='+this.props.cate), {
    headers : {'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({news:response.data['message']['items']})
        console.log(this.props.cate)
        console.log(response.data['message']['items'])
    }).catch(err =>{ console.log(err)})
}


async componentDidMount(){
    this.getData()
}
componentDidUpdate(prevProps){
  if(prevProps.cate !== this.props.cate){
     this.getData()
  }
}

render() {
    return  <div className="col-sm-12"> 
            
            

            {this.state.news.map((itm,k)=>{
               
 return(


              <div key={k} className="">
                <h2 className="l-title l-title--info"><span>{itm.category_name}</span></h2>
                 <Listitems newss={itm.route} /> 
                  <div className="l-details text-right mt-4 mb-3 ">
                  <p className=""><a href={'/'+itm.route}><b>Click here to see more stories from {itm.category_name} »</b></a>
                  <br/>
                 { itm.archive_link ?  <a href={itm.archive_link} target="_blank" rel="noreferrer"  ><b>Click here to see more  from Archives »</b></a> :  ''}
                 </p>
                 
                   
                  </div>
              </div>


)
})} 


   
             
           </div> 




      
    }
    }
    export default Group;