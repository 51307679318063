import React from 'react';
import axios from 'axios';
import Ads300x250 from '../Components/Ads300x250';
import { Link } from 'react-router-dom';
class Recommends extends React.Component {
    constructor(){
        super();
        this.state = {
           title: [],  title1: [],  title2: [],  title3: [],  title4: [],
          content: [],content1: [],content2: [],content3: [],content4: [],
          image: [],image1: [],image2: [],image3: [],image4: [],
          rec:[]
        }
    }
    async componentDidMount(){
        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.bmRecommends'), {
            headers : {'content-type': 'application/json'}
                    })
                    .then(response=>{
                      this.setState({rec:response.data['message']})
                     // console.log(response.data['data'])
                  })

                }
                render() {
    return <div>
    
            <Ads300x250 id="ZNE015" />
          
                <div className="latest-news">
                    <h3 className="l-title"><span> BM&nbsp;Recommends</span></h3>
                    <ul className="latest-news__list">
                    {this.state.rec.map((itm,k)=>{
                                            return(
                      
                        <li key={k} className="news__item"><Link to={process.env.PUBLIC_URL +'/news/'+itm.name} className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+itm.cover_photo+'?func=crop&w=110&h=75'} alt="Loading..." title="Loading..."/> */}
                        <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="recom-img" width="110px" height="75px"  alt="Loading..." title="Loading..."/></Link>
                      <div className="l-details">
                        <Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.short_title}</Link>
                        
                      </div>
                    </li> )
                                                            })} 
                    </ul>
                     
                </div>
                    

    </div>
     }
    }
    export default Recommends;
                    