import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
class Listitems extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        news : []
    }
}


componentDidMount(){
  this.getData();
}
componentDidUpdate(prevProps){
if(prevProps.newss !== this.props.newss){
 this.getData()
}
}



 getData(){
  //alert(this.props.newss)
  //console.log(process.env.REACT_APP_API_URL+'api/method/bm.api.getNewsList?route='+this.props.newss)
  axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getNewsList?route='+this.props.newss), {
    headers : {'content-type': 'application/json'}
})


.then(
  res=>{ this.setState({news:res.data['message']})
   }
)
.catch(
  err =>{ console.log(err)}
)

}
render() {
    return <div>
                  <ul className="news__list">

                  {this.state.news.map((itm,k)=>{
                    
                    if(k < 3){
                      if(itm.main_section){
                          var stripedHtml=itm.main_section.replace(/(<([^>]+)>)/gi,"");
      
                          return(
      
                          <li key={k} className="news__item d_block_res "><a className="l-thumb" href="#0">{/* <img src={process.env.REACT_APP_IMAGE+itm.cover_photo+'?func=crop&w=150&h=120'} alt="Loading..." title="Loading..."/> */}
                            <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="top-story-img" width="150px" height="120px"  alt="Loading..." title="Loading..."/></a>
                            <div className="l-details news-dtls">
                              <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
                              <p>{stripedHtml.slice(0,425)}</p>
                            </div>
                          </li>
                        )
                      }
                      else
                        return null
                    }
                    else
                      return null
})}            
                           </ul>
          </div>
    }
    }
    export default Listitems;