
import React from 'react';
import axios from 'axios';

import Ads300x600 from '../Components/Ads300x600';
import Tabitems from './Tabitems';
class   Style9 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],
        news3 : [],
        news4 : [],
        news5 : [],
        news6: [],
        news7: [],content0:[],content1:[]

        
        
    }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route=For sale&limit=8&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
  })
  .then(res=>{
    this.setState({news0:res.data['message'][0]})
    this.setState({news1:res.data['message'][1]})
    this.setState({news2:res.data['message'][2]})
    this.setState({news3:res.data['message'][3]})
    this.setState({news4:res.data['message'][4]})
    this.setState({news5:res.data['message'][5]})
    this.setState({news6:res.data['message'][6]})
    this.setState({news7:res.data['message'][7]})
    var stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content0:stripedHtml.slice(0,200)})
    stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content1:stripedHtml.slice(0,200)})
    
    //console.log(res.data['message'][0])
  })

  .catch(
    err =>{ console.log(err)}
  )
  try{
      var elem = document.getElementById('tab0');
      if(typeof elem !== 'undefined' && elem !== null) {
        elem.style.display = "block";
        elem.classList.add("is-active"); 
      }
    }
  catch(e){

  }
    
}

openCity(e, cityName){
    e.preventDefault();
    var i;
    var x = document.getElementsByClassName("tab-item");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";  
    }
    document.getElementById("tab"+cityName).style.display = "block";


    var lights = document.getElementsByClassName("is-active");
    while (lights.length)
        lights[0].className = lights[0].className.replace(/\bis-active\b/g, "");
    var element = document.getElementById("tb"+cityName);
    element.classList.add("is-active");
}


  render() {
return <div>
  <div className="row style9">
          <div className="col-sm-12">
            <div className="news-tab">
              <ul className="news-tabnav">
              {this.props.tabs.map((itm,k)=>{
                      return(<li key={k}  className="news-tabnav__item"><a id={"tb"+k} onClick={(e) => { this.openCity(e, k);}} className="news-tabnav__link " href="/#">{itm.category_name}</a></li>)
              })}
                
              </ul>

              
              <div className="news-tab__content">

               {this.props.tabs.map((itm,k)=>{

                      let settings ={
                        head:itm.category_name,
                        class:"info",
                        cat:itm.category_route,
                        limit:'8',
                        start:'0',
                        ads:itm.ad_slot,
                        bottomAds : itm.bottom_ad_zone,
                        tabId:'tab'+ k,

                      }

                      return(<Tabitems key={k} {...settings}/>)
                })}


              </div>
            </div>
          </div>
          <div className="col-sm-4">
           <Ads300x600 id="ZNE022"/>
          </div>
        </div>
</div>
}
}
export default Style9;