import React from 'react';
class Headerdate extends React.Component {
  state = {
    date: "",year:"",month:"",week:""
  };

  componentDidMount() {
    this.getDate();
  }

  getDate = () => {
    var date = new Date().getDate();
    this.setState({ date });
    var year = new Date().getFullYear();
    this.setState({ year });
    var month = new Date().toLocaleString('default', { month: 'short' })
    this.setState({ month });
    var week = new Date().toLocaleString('default', {  weekday: 'long'})
    this.setState({ week });


  };
  render() {
    const { date } = this.state;
    const { year } = this.state;
    const { month } = this.state;
    const { week } = this.state;
return <div>
    <div className="header__date"><span>{ month } / { year }</span><span className="header__day">{ date }</span><span>{ week }</span></div>
          </div>

}
}
export default Headerdate;