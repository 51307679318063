import React from 'react';
import axios from 'axios';

class Bigstory extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        news : undefined,
        banner : [],
        subbanner : [],
    }
}
async componentDidMount(){

  if(this.props.news.length>0){
    this.setState({news:this.props.news[0]})

  }

  /* if(this.props.banner.length>0){
    this.setState({subbanner:this.props.banner})

  } */
  //+console.log(this.props.banner)
  
  /* axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getHomerows'), {
    headers : {'content-type': 'application/json'}
      })
      .then(res=>{ 
          this.setState({banner:res.data['message']})
          console.log(res.data['message'])
      }
      )
      .catch(err =>{ console.log(err)} ) */




}
  render() {
   if( this.state.news){
    return <div>
        <div className="row">
          <div className="col-16">
            <div className="hover14 l-ad-wrap zoom">
              <div>
                <figure><a href={this.state.news['link']} >
                  <img alt ="Big Story" src={process.env.REACT_APP_API_URL+this.state.news['banner_image']}  />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
    </div>
   }
   else{
     return <> </>
   }

}
}
export default Bigstory;

