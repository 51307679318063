import React from 'react';
import axios from 'axios';
import AdvShow from './AdvShow';
class Ads300x250 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        ad : []
    }
}
async componentDidMount(){
    if(this.props.id){
      axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getAd?zone='+this.props.id), {
        headers : {'content-type': 'application/json'}
      })
      .then(
          res=>{
            if(res.data['message']){
              this.setState({ad:res.data['message']})
             // console.log(this.props.id)
            //  console.log(res.data['message'])
            }
      })
      .catch(
          err =>{ console.log(err)}
        )

    }
      
}
  render() {
return <div className="con300x250">
      {this.state.ad.map((itm,k)=>{
             return(
               <div key={k} className="l-ad-wrap ads300x250">
                      <AdvShow ad={itm} />
                </div>)
      })}
</div>
}
}
export default Ads300x250;

