import React from 'react';
import ReactHtml from 'raw-html-react';
class AdvShow extends React.Component {
render() {
                return <>
                    {(() => {
                        if (this.props.ad['campaign_type'] === 'Graphic Banner') {
                            return ( <a target="_blank"  rel="noreferrer" href={this.props.ad['redirect_url']}>
                            <img className='adImg' src={process.env.REACT_APP_API_URL + this.props.ad['banner_image']}  alt="advertisement" title="advertisement"/> </a>)
                            }
                        else{
                            return ( <ReactHtml html={this.props.ad['ad_html_code']}/>)
                        }
                    })()}
                </>      
            }
}
export default AdvShow;




            
         