import React  from 'react';
import Fullwidth from '../Components/Fullwidth';
import Style11 from '../Components/Style11';
import Style10 from '../Components/Style10';
import Style7 from '../Components/Style7';
import Style1 from '../Components/Style1';
import Style8 from '../Components/Style8';
import Style9 from '../Components/Style9';
import Style2 from '../Components/Style2';
import Style3 from '../Components/Style3';
import Style4 from '../Components/Style4';
import Style5 from '../Components/Style5';
import Style6 from '../Components/Style6';
import Youtube from '../Components/Youtube';
import Bigstory from '../Components/Bigstory';
import SubBanner from '../Components/SubBanner';
import Topstory from '../Components/Topstory';
import Mainstory from '../Components/Mainstory';
import Leadstory from '../Components/Leadstory';
import axios from 'axios';


class Home extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        homeRows:[],
        bigstory : undefined,
        mainstory :undefined,
        topstory : undefined,
        latestNews: undefined,
        leadStory: undefined,
        showleadStory: false,
        scrollY:undefined,
        isFullwidth:false,
        showAssociation: false,
        showSpiritual: false,
        showUK: false,
        showCharity: false,
        showMatrimony: false,
        showSale: false,
        showSports: false,
        showYoutube: false,
        showAbroad: false,
        showIndia: false,
        showClassified: false,
        homeTabs:[]
    }
  }
  
  async componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)

        axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getMainNews'), {
              headers : {'content-type': 'application/json'}
        })
        .then(res=>{ 
             // console.log("Full News")
             // console.log(res.data['message'])
              this.setState({bigstory:res.data['message']['bannerNews']})
              this.setState({mainstory:res.data['message']['mainStory']})
              this.setState({topstory:res.data['message']['topStories']})
              this.setState({latestNews:res.data['message']['latestNews']})
          }
        )
        .catch(err =>{ console.log(err)} )

        axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getHomerows'), {
              headers : {'content-type': 'application/json'}
        })
        .then(res=>{ 
             //console.log(res.data['message'])
              this.setState({homeRows:res.data['message']['rows']})
              this.setState({homeTabs:res.data['message']['tabs']})
              this.setState({subBanner:res.data['message']})
          }
        )
        .catch(err =>{ console.log(err)} )
  }

  loadLeadstory(){
    if(!this.state.showleadStory){
      this.setState({showleadStory:true})
      axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.leadStories'),  {
              headers : {'content-type': 'application/json'}
        })
        .then(response=>{
              this.setState({Leadstory:response.data['message']})
        })
    }
      

  }
  handleScroll = (event) => {
    this.setState({
        scrollY: window.scrollY
    });
    

    if (this.state.scrollY > 800 && !this.state.showleadStory) { //showleadStory
      this.loadLeadstory()
    }
    
    if (window.scrollY > 1200  && !this.state.showAssociation) { //Association
      this.setState({showAssociation:true})
    }

    if (window.scrollY > 1500  && !this.state.showSpiritual) { //showSpiritual
      this.setState({showSpiritual:true})
    }
    if (window.scrollY > 1700  && !this.state.showUK) { //showUK
      this.setState({showUK:true})
    }
    if (window.scrollY > 1900  && !this.state.showCharity) { //showCharity
      this.setState({showCharity:true})
    }
    if (window.scrollY > 2100  && !this.state.showMatrimony) { //showMatrimony
      this.setState({showMatrimony:true})
    }
    if (window.scrollY > 2100  && !this.state.showSale) { //showSale
      this.setState({showSale:true})
    }

    if (window.scrollY > 2300  && !this.state.showSports) { //showSports
      this.setState({showSports:true})
    }

    if (window.scrollY > 2500  && !this.state.showYoutube) { //showYoutube
      this.setState({showYoutube:true})
    }
    
    if (window.scrollY > 2700  && !this.state.showAbroad) { //showAbroad
      this.setState({showAbroad:true})
    }

    if (window.scrollY > 2900  && !this.state.showIndia) { //showIndia
      this.setState({showIndia:true})
    }

    if (window.scrollY > 2900  && !this.state.showClassified) { //showClassified
      this.setState({showClassified:true})
    }

  }
  
  
  render() {

      return <div>
            
          <div className="main-wrapper">
      
            <div className="container">

              {(() => {
                  if (this.state.bigstory) {
                      return (<Bigstory news={this.state.bigstory} />)
                  } 
              })()}
              {(() => {
                  if (this.state.subBanner) {
                      return (<SubBanner banner={this.state.subBanner} />)
                  } 
              })()}
              {(() => {
                  if (this.state.mainstory) {
                      return (<Mainstory news={this.state.mainstory} />)
                  } 
              })()}
              
              {(() => {
                  if (this.state.topstory) {
                      return (<Topstory news={this.state.topstory}  />)
                  } 
              })()}
              
                  <div className="row">
                    <div className="col-16">
                    <Fullwidth zone="ZNE006"/>
                    </div>
                  </div>
                   
              {(() => {
                  if (this.state.Leadstory) {
                      return (<Leadstory news={this.state.Leadstory} lnews={this.state.latestNews} />)
                  } 
              })()}

              {(() => {
                
                if (this.state.homeRows.length > 0) {
                      var indents = [];


                      for (let i = 0; i < this.state.homeRows.length; i++) {
                        if (this.state.homeRows[i]['enabled']===1) {
                          let startNews = 0
                          for (let j = i-1; j >= 0; j--) {
                              //console.log(this.state.homeRows[i]['category_route'] + " i " + i + " :: " + this.state.homeRows[j]['category_route'] + " j " + j)
                              if(this.state.homeRows[i]['category_route']=== this.state.homeRows[j]['category_route']){
                                startNews = parseInt(startNews) + parseInt(this.state.homeRows[j]['news'])

                              }
                          }
                          let settings ={
                                head:this.state.homeRows[i]['category_name'],
                                head2:this.state.homeRows[i]['category_name2'],
                                class:this.state.homeRows[i]['header_class'],
                                class2:this.state.homeRows[i]['header2_class'],
                                cat:this.state.homeRows[i]['category_route'],
                                cat2:this.state.homeRows[i]['category_route2'],
                                limit:this.state.homeRows[i]['news'],
                                start:parseInt(startNews),
                                ads:this.state.homeRows[i]['ad_slot'],
                                bottomAds : this.state.homeRows[i]['bottom_ad_zone'],
                                TagName:this.state.homeRows[i]['style']
                                
                          }
                          console.log(startNews)
                          

                          switch (this.state.homeRows[i]['style']) {
                            case "Style1":  
                              indents.push(<Style1  {...settings} />)
                              break;
                            case "Style2":
                              indents.push(<Style2  {...settings} />)
                              break;
                            case "Style3":
                              indents.push(<Style3  {...settings} />)
                              break;
                            case "Style4":
                              indents.push(<Style4  {...settings} />)
                              break;
                            case "Style5":
                              indents.push(<Style5  {...settings} />)
                              break;
                            case "Style6":
                              indents.push(<Style6  {...settings} />)
                              break;
                            case "Style7":
                              indents.push(<Style7  {...settings} />)
                              break;
                            case "Style8":
                              indents.push(<Style8  {...settings} />)
                              break;
                            case "Style10":
                              indents.push(<Style10  {...settings} />)
                              break;
                            case "Style11":
                              indents.push(<Style11  {...settings} />)
                              break;
                            case "Youtube":
                              indents.push(<Youtube  {...settings} />)
                              break;
                            default:
                              indents.push(<Style1  {...settings} />)
                              break;
                          }
                          
                      } 
                      
                       
                         
                      } 
                      return indents;
                   

                }
                  
                  
                   
              })()}
        
          
             
              {(() => {
                  if (this.state.showClassified) {
                    return ( <Style9  tabs={this.state.homeTabs}/>)
                    } 
              })()}
                  

            </div>
            
          </div>
          </div>
    }
  }
  export default Home;
  