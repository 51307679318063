import React from 'react';
import axios from 'axios';
import AdvShow from './AdvShow';

class Ads300x90 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        ad : []
    }
}
async componentDidMount(){
    if(this.props.id){
        axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getAd?zone='+this.props.id), {
          headers : {'content-type': 'application/json'}
        })
        .then(
            res=>{ this.setState({ad:res.data['message']})
          // console.log("aa - " + this.props.id)
          // console.log(res.data['message'])
        })
        .catch(
            err =>{ console.log(err)}
          )
    }
      
}

  render() {
    if(this.state.ad){
      return <div className={this.props.id}>
      {this.state.ad.map((itm,k)=>{
             return <div key={k} className="header-ads__item">
                      <AdvShow ad={itm} />
                </div>
      })}
</div>
    }
    else{
      return <> </>
    }

              
            
}
}
export default Ads300x90;