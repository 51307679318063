
import React from 'react';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Latestnews from './Latestnews';
import Ads300x90 from './Ads300x90';
class Leadstory extends React.Component {
  constructor(){
    super();
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],
        news3 : [],
        news4 : [],
        news5 : [],
        news6 : [],
        news7 : [],
        news8 : [],
        news9 : [],
        news10 : [],
        news11 :[],
        name1:[],
        lnews:undefined

        
    }
}
componentDidMount(){

  //console.log("Leadstory News")
  //console.log(this.props.news)
  if(this.props.news.length>0){
    this.setState({lnews:this.props.lnews})
    this.setState({news0:this.props.news[0]})
    this.setState({news1:this.props.news[1]})
    this.setState({news2:this.props.news[2]})
    this.setState({news3:this.props.news[3]})
    this.setState({news4:this.props.news[4]})
    this.setState({news5:this.props.news[5]})
    this.setState({news6:this.props.news[6]})
    this.setState({news7:this.props.news[7]})
    this.setState({news8:this.props.news[8]})
    this.setState({news9:this.props.news[9]})
    this.setState({news9:this.props.news[9]})
    this.setState({news10:this.props.news[10]})
    this.setState({news11:this.props.news[11]})

  }
  /*axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.leadStories'),  {
    headers : {'content-type': 'application/json'}
})
.then(response=>{
  this.setState({news0:response.data['message'][0]})
  this.setState({news1:response.data['message'][1]})
  this.setState({news2:response.data['message'][2]})
  this.setState({news3:response.data['message'][3]})
  this.setState({news4:response.data['message'][4]})
  this.setState({news5:response.data['message'][5]})
  this.setState({news6:response.data['message'][6]})
  this.setState({news7:response.data['message'][7]})
  this.setState({news8:response.data['message'][8]})
  this.setState({news9:response.data['message'][9]})
  this.setState({news9:response.data['message'][9]})
  this.setState({news10:response.data['message'][10]})
  this.setState({news11:response.data['message'][11]})
  

 // console.log(response.data['data'][0])
})*/
}
componentDidUpdate(prevProps){
  if(prevProps.lnews !== this.props.lnews){
      this.setState({lnews:this.props.lnews})
  }
 
  
}
  render() {
return <div>
 <div className="row lead-story">
              
              
          <div className="col-sm-12">

            {/* <!-- news starts--> */}
            <div className="news news--wrap">
              
              <div className="row">
                
                <div className="col-sm-8">
                  <div className="ads ">
                          <Ads300x90 id="ZNE011" />
                  </div>   

                  <ul className="news__list">
                    <li className="news__item">
                      <div className="l-thumb">
                       {/*  <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..."/> */}
                       <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news0['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news0['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}><ReactHtml html={this.state.news0['title']}/></Link>
                        </div>      
                        </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                      <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news1['start_date']}
                      </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news1['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}><ReactHtml html={this.state.news1['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                      <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news2['start_date']}
                      </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}><ReactHtml html={this.state.news2['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news3['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news3['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news3['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news3['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3['name']}><ReactHtml html={this.state.news3['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news4['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news4['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news4['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news4['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news4['name']}><ReactHtml html={this.state.news4['title']}/></Link>
                      </div>
                    </li>
                    {/*<li className="news__item">
                      <img src={ process.env.REACT_APP_API_URL+this.state.news5['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news5['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news5['start_date']}</Moment></div>
                        <p><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news5['name']}><ReactHtml html={this.state.news5['title']}/></Link></p>
                      </div>
                    </li>*/ }
                     
                  </ul>

                  <div className="adsbtm ">
                          <Ads300x90 id="ZNE025" />
                  </div> 
                </div>
                <div className="col-sm-8">
                 <div className="ads ">
                          <Ads300x90 id="ZNE012" />
                  </div>
                  <ul className="news__list">
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news6['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news5['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news5['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news5['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news5['name']}><ReactHtml html={this.state.news5['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news6['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news6['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news6['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news6['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news6['name']}><ReactHtml html={this.state.news6['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news7['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news7['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news7['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news7['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news7['name']}><ReactHtml html={this.state.news7['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news8['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news8['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news8['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news8['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news8['name']}><ReactHtml html={this.state.news8['title']}/></Link>
                      </div>
                    </li>
                    <li className="news__item">
                      <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news9['cover_photo']+'?func=crop&w=110&h=80'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news9['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news9['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news9['start_date']}</Moment></div>
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news9['name']}><ReactHtml html={this.state.news9['title']}/></Link>
                      </div>
                    </li>
                    {/*<li className="news__item">
                      <img src={ process.env.REACT_APP_API_URL+this.state.news11['cover_photo']} className="lead-story-img" width="110px" height="80px"  alt="Loading..." title="Loading..."/>
                      </div>
                      <div className="l-details news-dtls3">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news11['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news11['start_date']}</Moment></div>
                        <p><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news11['name']}><ReactHtml html={this.state.news11['title']}/></Link></p>
                      </div>
                  </li>*/}
                  </ul>
                  <div className="adsbtm ">
                          <Ads300x90 id="ZNE026" />
                  </div>
                </div>
              </div>
            </div>
           {/*  <!-- news ends--> */}
          </div>
          <div className="col-sm-4">
              {(() => {
                  
                  if (this.state.lnews) {
                      return (<Latestnews lnews={this.state.lnews} />)
                  } 
              })()}
          </div>
        </div>
</div>
}
}
export default Leadstory;
                      
                    
                   

                    
                  