import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1000}
    height={306}
    viewBox="0 0 1000 306"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
     <rect x="550" y="386" rx="3" ry="3" width="88" height="6" /> 
    <rect x="556" y="378" rx="3" ry="3" width="52" height="6" /> 
    <rect x="230" y="369" rx="3" ry="3" width="410" height="25" /> 
    <rect x="517" y="374" rx="3" ry="3" width="380" height="6" /> 
    <rect x="517" y="390" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="583" cy="382" r="20" /> 
    <rect x="11" y="1" rx="0" ry="0" width="345" height="289" /> 
    <rect x="370" y="6" rx="0" ry="0" width="900" height="32" /> 
    <rect x="540" y="370" rx="0" ry="0" width="900" height="22" /> 
    <rect x="575" y="376" rx="0" ry="0" width="900" height="35" /> 
    <rect x="586" y="365" rx="0" ry="0" width="10" height="22" /> 
    <rect x="370" y="54" rx="0" ry="0" width="900" height="32" /> 
    <rect x="370" y="115" rx="0" ry="0" width="885" height="11" /> 
    <rect x="370" y="150" rx="0" ry="0" width="885" height="11" /> 
    <rect x="370" y="182" rx="0" ry="0" width="885" height="11" /> 
    <rect x="370" y="216" rx="0" ry="0" width="885" height="11" /> 
    <rect x="370" y="251" rx="0" ry="0" width="885" height="11" /> 
    <rect x="370" y="282" rx="0" ry="0" width="300" height="11" />
  </ContentLoader>
)

export default MyLoader

