import React from 'react';
class Searchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      srch:[],check:false
    }
}
async searchResult(e, keyword){
  //alert(keyword.length)
  if(keyword.length){
  this.setState({srch:keyword})
  this.setState({check:true})
  }
  else {
    this.setState({check:false})
  }
}
  render() {
return <div>
   <div className="topbar__search">
              <form className="search__form">
                <input className="search__txt" type="text" placeholder="Search Here"  onChange={e => this.searchResult(e, e.target.value)}  name="x"/>
              {this.state.check ? <a className="search__btn" href={'/search/'+this.state.srch }>Go</a> : <a  href="#0" className="search__btn">Go</a>}  
              </form>
            </div>
</div>
}
}
export default Searchbar;