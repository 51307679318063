import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import MyLoader from './MyLoader';
import Ads300x250 from '../Components/Ads300x250';
import Ads300x90 from './Ads300x90';

class   Mainstory extends React.Component {
    constructor(){
        super();
        this.state = {
           title: [],  title1: [],  title2: [],  title3: [],  title4: [],
          content: [],content1: [],content2: [],content3: [],content4: [],
          image: [],image1: [],image2: [],image3: [],image4: [],route:[],date:[],
          sty:[],
          isLoading: true 
        }
    }
    async componentDidMount(){
        //console.log("Main News")
        //console.log(this.props.news)
        if(this.props.news.length>0){
            this.setState({title:this.props.news[0]['title']})
            this.setState({content:this.props.news[0]['main_section']})
            this.setState({image:this.props.news[0]['cover_photo']})
            this.setState({route:this.props.news[0]['name']})
            this.setState({date:this.props.news[0]['start_date']})
            var stripedHtml=this.props.news[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
            this.setState({content:stripedHtml.slice(0,800)})
            //console.log(response.data['message'])
            this.setState({isLoading: false})
    
        }
        

        
    }
render() {
    return(
        this.state.isLoading ? <MyLoader/> :  <div>   
       
        <div className="row main-story">
           
            <div className="col-sm-12">
                <div className="ads mb-3">
                        <Ads300x90 id="ZNE009" />
                </div>   
                <div className="news">
                        <ul className="news__list">
                            <li className="news__item news__item--main"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.route} className="l-thumb">
                     {/* <img src={process.env.REACT_APP_IMAGE+this.state.image+'?func=crop&w=360&h=305'}alt="Loading..." title="Loading..." />  */}
                                 <img src={ process.env.REACT_APP_API_URL+this.state.image} className="main-story-img" width="360" height="300"  alt="Main Story" title="Main Story"/> 
                                </Link>
                                
                            <div className="l-details news-dtls6">
                                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.route}>{this.state.title}</Link></h3>
                                    <div className="l-meta l-meta--hrz2">
                                    <a href="#0"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.date}
                                       </Moment> </a>
                                    </div>
                                    <p>{this.state.content}...</p>
                                </div>
                                </li>
                        </ul>
                     
                    </div>
            </div>
            <div className="col-sm-4">
        
                      <Ads300x250 id="ZNE004"/>
        
        
            </div>
        
        
                </div>
              
        </div>
    )  

 
}
}
export default Mainstory;
        


     