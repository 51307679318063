
import React from 'react';
import Inke from '../Components/Inke';
import Ads300x250 from '../Components/Ads300x250';
class   Style8 extends React.Component {
  constructor(){
    super();
    this.state = {
      

        
        
    }
}

  render() {
return <div>
  <div className="row">
          <div className="col-sm-6">
           {/*  <!-- news starts--> */}
          <Inke  head={this.props.head} class={"l-title--"+ this.props.class} cat={this.props.cat}/>
           {/*  <!-- news ends--> */}
          </div>
          <div className="col-sm-6">
            {/* <!-- news starts--> */}
          <Inke  head={this.props.head2} class={"l-title--"+ this.props.class2} cat={this.props.cat2}/>
           {/*  <!-- news ends--> */}
          </div>
          <div className="col-sm-4">
          <Ads300x250 id={this.props.ads} />

          </div>
        </div>
</div>
}
}
export default Style8;