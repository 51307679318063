import React from 'react';
import ScrollUpButton from "react-scroll-up-button";
class Footer extends React.Component {
  render() {
return <div>
<ScrollUpButton ContainerClassName="MyOverRideClass" TransitionClassName="MyOverRideTransitionedClass"/>
   <div className="footer">
        <div className="footer-main">
          <div className="container">
            <div className="footer__wrap">
              <div className="footer__sitemap">
              <div className="footer__logo"><a href="/index" className="footer__logo"><img src="/images/logo-footer.png" alt="MarunadanMalayalee.com" title="MarunadanMalayalee.com"/></a>
                </div>
                <div className="footer__block">
                  <h3>Quick Links</h3>
                  <ul className="footer__nav">
                      <li><a href="/post-your-news.html">Post Your News </a></li>
                      <li><a href="/about-us.html">About Us</a></li>
                      <li><a href="/grievance-redressal.html">Grievance</a></li>
                      <li><a href="/adv-traff.html">ADV Traff</a></li>
                      <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                      <li><a href="/terms-and-use.html">Terms And Use</a></li>
                      <li><a href="/disclaimer.html">Disclaimer</a></li>
                      <li><a href="/contact-us.html">Contact Us</a></li>
                   </ul>
               </div>
  
              </div>
      <div className="footer__right">
                <h3>connect with us</h3>
                <ul className="footer__social">
                  <li><a href="https://www.facebook.com/britishmalayali.co.uk?fref=ts"target="_blank" rel="noreferrer" ><i className="fa fa-facebook"></i></a></li>
                  {/* <li><a href=""><i className="fa fa-twitter"></i></a></li>
                  <li><a href=""><i className="fa fa-youtube"></i></a></li> */} 
                </ul>
      </div>
      </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer__copyright"><span>© Copyright britishmalayali.co.uk 2021. All rights reserved.</span></div>
          </div>
        </div>
      </div>
    </div>
    }
}
export default Footer;