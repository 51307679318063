import React from 'react';
class Postyournews extends React.Component {
  render() {
return <div>
<div className="container">
        <div className="row">
            <div className="col-sm-16">
            <div className="col-sm-12">
<h1>Post Your News</h1>
<form>
<div className="form-group ">
      <label for="exampleInputEmail1">Your Name*</label>
         <div>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
         </div>
</div>
<div className="form-group  ">
       <label for="exampleInputEmail1">Your Email*</label>
          <div>
             <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=""/>
          </div>
</div>
<div className="form-group ">
       <label for="exampleInputPassword1">Your PhoneNumber</label>
         <div>
            <input type="password" className="form-control" id="exampleInputPassword1" placeholder=""/>
          </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">Headline</label>
      <div>
         <input type="password" className="form-control" id="exampleInputPassword1" placeholder=""/>
      </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">News</label>
      <div>
         <p>Click Here To Change Your Language</p>
         
         <textarea className="form-control " rows="7" id="comment"></textarea>
      </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">Upload Image</label>
      <div>
      <input type="file" id="myFile" name="filename"/>
      </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">Upload attachment 1</label>
      <div>
      <input type="file" id="myFile" name="filename"/>
      </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">Upload attachment 2</label>
      <div>
      <input type="file" id="myFile" name="filename"/>
      </div>
</div>
<div className="form-group ">
      <label for="exampleInputPassword1">Upload attachment 3</label>
      <div>
      <input type="file" id="myFile" name="filename"/>
      </div>
</div>
<button type="submit " className="btn btn-primary">Submit Your News</button> 
{/* <input type="submit" name="submit" className="wpcf7-form-control wpcf7-submit" value="Submit your News"></input> */}
</form>









             </div>
        </div>
        
        





        
        </div>
            
            
            
            
            
</div>
    
 </div>
      }
    }
    export default Postyournews;


   