import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {Helmet} from "react-helmet";
import './index.css';
import Author from './Pages/Author';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';

import Menunews from './Pages/Menunews';
import Mainnews from './Pages/Mainnews';
import Aboutus from './Pages/Aboutus';
import Grievance from './Pages/grievance-redressal';

import Contactus from './Pages/Contactus';
import Postyournews from './Pages/Postyournews';
import reportWebVitals from './reportWebVitals';
import Search from './Pages/Search';

import Disclaimer from './Pages/Disclaimer';
import ScrollToTop from './Components/ScrollToTop';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-17091348-3');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    
    <Router>
         <Header />
         <Switch>
               <Route exact path="/">
                  <Home />
               </Route>
               <Route exact path="/index">
                  <Home />
               </Route>
              
               <Route exact path="/index.html">
                  <Home />
               </Route>            
               <Route exact path="/index.php">
                  <ScrollToTop />
                  <Mainnews />
               </Route> 
               <Route exact path="/about-us.html">
                  <Aboutus />
               </Route> 
               <Route exact path="/contact-us.html">
                  <Contactus />
                  
               </Route> 
               <Route exact path="/grievance-redressal.html">
                  <Grievance />
                  
               </Route> 
               <Route exact path="/post-your-news.html">
                  <Postyournews />
         
                  
               </Route> 
               <Route exact path="/disclaimer.html">
                  <Disclaimer  head="Disclaimer" url="disclaimer" />
                  
               </Route>
               {/* <Route exact path="/breakingnews.html">
                  <Breakingnews />
                  
               </Route>  */}
              
            
               <Route exact path="/adv-traff.html">
                  <Disclaimer head="ADV Traff" url="adv-traff" />
                  
               </Route> 
               <Route exact path="/privacy-policy.html">
                  <Disclaimer head="Privacy Policy" url="privacy-policy"/>
                  
               </Route> 
               <Route exact path="/terms-and-use.html">
                  <Disclaimer head="Terms And Use" url="terms-of-use" />
                  
               </Route> 
            
            {/*  <Route exact path= "/:slug4/:slug3">
                  <Listnews />
               </Route> */} 
                 <Route exact path="/byauthor/:slug1">
                  <Author  role="author"/>   
                </Route> 
 
                <Route exact path="/byline/:slug1">
                  <Author  role="byline"/>   
                </Route> 
                
                <Route exact path="/search/:slug5">
                  <Search />   
               </Route> 
               <Route path="/news/:slug2">
                  <ScrollToTop />
                  <Mainnews />
               </Route> 
               <Route path="/:slug4/:slug3">
                  <ScrollToTop />
                  <Menunews />
               </Route> 
               <Route path="/:slug4">
                  <ScrollToTop />
                  <Menunews />
               </Route>


               
         </Switch>
      <Footer />
      <Helmet>
      <script type="text/javascript" src="js/main.js"></script>
      </Helmet>
    </Router>
    
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
