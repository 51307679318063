
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Adshorizontal from './Adshorizontal';
class   Style11 extends React.Component {
  constructor(){
  super();
  this.state = {
  
      sty :[]
      
  }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit='+this.props.limit+ '&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
})
.then(res=>{
  
    this.setState({sty:res.data['message']})
    //console.log(res.data['message'])




})

.catch(
  err =>{ console.log(err)}
)
}
  render() {
return <div>

<div className="row">
          <div className="col-16">
            <div className="news-bg">
              <ul className="news-bg__list ">
                    {this.state.sty.map((itm,k)=>{
                      if(k < 4){
                        if(itm.main_section){
                          var stripedHtml=itm.main_section.replace(/(<([^>]+)>)/gi,"");
                          return(
                            
                                    <li key={k} className="news-bg__item b-line" >
                                      <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
                                            <p>{stripedHtml.slice(0,199)}</p>
                                            
                                    </li>
                                  
                          )}
                          else
                            return null
                      }
                          
                      else
                          return null
                })} 
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
                  <div className="col-sm-16 d-flex justify-content-center">
                          <Adshorizontal id={this.props.bottomAds}/>
                  </div>
              </div>          
    </div>
    }
}
export default Style11;
