
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Ads300x250 from '../Components/Ads300x250';
import Adshorizontal from './Adshorizontal';
class   Style2 extends React.Component {
  constructor(){
    super();
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],content0:[],content1:[],content2:[]
        
        
    }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit='+this.props.limit + '&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
})
.then(res=>{
  this.setState({news0:res.data['message'][0]})
  this.setState({news1:res.data['message'][1]})
  this.setState({news2:res.data['message'][2]})
  var stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content0:stripedHtml.slice(0,190)})
  stripedHtml=res.data.message[1]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content1:stripedHtml.slice(0,190)})
  stripedHtml=res.data.message[2]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content2:stripedHtml.slice(0,190)})
  
 // console.log(res.data['message'][0])
})

.catch(
  err =>{ console.log(err)}
)
}
  render() {
return <div>
   <div className="row spitual">
          <div className="col-sm-12">
            {/* <!-- news starts--> */}
            <div className="news-hrz">
            <h2 className={'l-title l-title--'+this.props.class}><span>{this.props.head}</span></h2>
              <ul className="news-hrz__list">
                <li className="news-hrz__item"> <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=290&h=140'}alt="Loading..." title="Loading..." /> */}
                <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="sty1-asso-img" width="290px" height="140px"  alt="Loading..." title="Loading..."/></Link>
                  <div className="l-details news-dtls2">
                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}>{this.state.news0['title']}</Link></h3>
                    <p> {this.state.content0}...</p>
                  </div>
                </li>
                <li className="news-hrz__item"> <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=290&h=140'}alt="Loading..." title="Loading..." /> */}
                <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="sty1-asso-img" width="290px" height="140px"  alt="Loading..." title="Loading..."/></Link>
                  <div className="l-details news-dtls2">
                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}>{this.state.news1['title']}</Link></h3>
                    <p> {this.state.content1}...</p>
                  </div>
                </li>
                <li className="news-hrz__item"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=290&h=140'}alt="Loading..." title="Loading..." /> */}
                <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="sty1-asso-img" width="290px" height="140px"  alt="Loading..." title="Loading..."/></Link>
                  <div className="l-details news-dtls2">
                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}>{this.state.news2['title']}</Link></h3>
                    <p> {this.state.content2}...</p>
                  </div>
                </li>
              </ul>
            </div>
           {/*  <!-- news ends--> */}
          </div>
          <div className="col-sm-4 pt-5">
          <Ads300x250 id={this.props.ads}/>
          </div>
        </div>
        <div className="row">
                  <div className="col-sm-16 d-flex justify-content-center">
                          <Adshorizontal id={this.props.bottomAds}/>
                  </div>
        </div>
</div>
}
}
export default Style2;