import React from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class   Style10 extends React.Component {
  constructor(){
    super();
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],
        news3 : [],
        news4 : [],
      }
    }
    componentDidMount(){
      axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route=Death&limit=5&start=' +this.props.start), {
        headers : {'content-type': 'application/json'}
    })
    .then(response=>{
      this.setState({news0:response.data['message'][0]})
      this.setState({news1:response.data['message'][1]})
      this.setState({news2:response.data['message'][2]})
      this.setState({news3:response.data['message'][3]})
      this.setState({news4:response.data['message'][4]})
      //console.log(response.data['message'][0])
    })
    }
  render() {

return <div>

 

 <div className="row">

          <div className="col-sm-16">

           {/*  <!-- story-slider starts--> */}

            <div className="story-slider">

              <h2 className="l-title"><span>Ever Green</span></h2>

              <div className="story-slider__block owl-carousel">

                <div className="story-slider__item">

                  <div className="l-thumb l-thumb--bottom">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=200&h=115'}alt="story-slider" title="story-slider" /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="styl10-slid-img" width="200px" height="115px"  alt="Loading..." title="story-slider"/>

                  </div>

                  <div className="l-date"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.news0['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news0['start_date']}</Moment></div>
                  
                  <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}>{this.state.news0['title']} </Link></h3>


                 

                </div>

                <div className="story-slider__item"> 

                  <div className="l-thumb l-thumb--bottom">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=200&h=115'}alt="story-slider" title="story-slider" /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="styl10-slid-img" width="200px" height="115px"  alt="Loading..." title="story-slider"/>

                  </div>

                  <div className="l-date"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.news1['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news1['start_date']}</Moment></div>
                  
                <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}>{this.state.news1['title']} </Link></h3>



              

                </div>

                <div className="story-slider__item"> 

                  <div className="l-thumb l-thumb--bottom">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=200&h=115'}alt="story-slider" title="story-slider" /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="styl10-slid-img" width="200px" height="115px"  alt="Loading..." title="story-slider"/>


                  </div>

                  <div className="l-date"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.news2['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                  
                 

                  <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}>{this.state.news2['title']} </Link></h3>

                

                </div>

                <div className="story-slider__item "> 

                  <div className="l-thumb l-thumb--bottom">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news3['cover_photo']+'?func=crop&w=200&h=115'}alt="story-slider" title="story-slider" /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news3['cover_photo']} className="styl10-slid-img" width="200px" height="115px"  alt="Loading..." title="story-slider"/>

                  </div>

                  <div className="l-date"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.news3['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news3['start_date']}</Moment></div>
                  

                <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3['name']}>{this.state.news3['title']} </Link></h3>


                  

                </div>

                <div className="story-slider__item"> 

                  <div className="l-thumb l-thumb--bottom">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news4['cover_photo']+'?func=crop&w=200&h=115'}alt="story-slider" title="story-slider" /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news4['cover_photo']} className="styl10-slid-img" width="200px" height="115px"  alt="Loading..." title="story-slider"/>

                  </div>

                  <div className="l-date"> <Moment format="MMMM DD / YYYY" withTitle>{this.state.news4['start_date']}
                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news4['start_date']}</Moment></div>
                  
                <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news4['name']}>{this.state.news4['title']} </Link></h3>

                 

                </div>

              </div>

            </div>

            {/* <!-- story-slider ends--> */}

          </div>

        </div>

</div>


  }
}


export default Style10;