import React from 'react';
import { Link } from 'react-router-dom';
class Menulist extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        list : [],
        news0 :[], news1 :[], news2 :[], news3 :[],
        
    }
}
componentDidMount(){

/*  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.subMenu?parentRoute='+this.props.groups), {
    headers : {'content-type': 'application/json'}
})

.then(
  res=>{ this.setState({list:res.data.message['items']})
  this.setState({news0:res.data.message['news'][0]})
  this.setState({news1:res.data.message['news'][1]})
  this.setState({news2:res.data.message['news'][2]})
  this.setState({news3:res.data.message['news'][3]}) }
)
.catch(
  err =>{ console.log(err)}
)
*/
 // console.log(this.props.subs)
  this.setState({list:this.props.subs.items})
  this.setState({news0:this.props.subs['news'][0]})
  this.setState({news1:this.props.subs['news'][1]})
  this.setState({news2:this.props.subs['news'][2]})
  this.setState({news3:this.props.subs['news'][3]})

}
  render() {
    if(this.state.list && this.state.list.length > 0)
    {
      return <div>
        <div className="meganav">
                  <div className="meganav__item meganav__item--links">
                      <ul className="mega-links">
                          {this.state.list.map((itm,k)=>{
                              return(
                  <li key={k} className="mega-links__item"><Link to={process.env.PUBLIC_URL +'/'+itm.route}><strong>{itm.category_name}</strong></Link></li>
      )
      })} 
      </ul>
      </div>


      
        {(() => {
        if (this.state.news0) {
          return (
            <div className="meganav__item">
                <ul className="mega-news mega-news--latest">
                    <li className="mega-news__item">
                       <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0.name} className="l-thumb">
                          <div className="l-thumb l-thumb--bottom">
                             {/*  <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=290&h=140'}alt="Loading..." title="Loading..." /> */}
                             <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="sty1-asso-img" width="290px" height="140px"  alt="Loading..." title="Loading..."/>
                          </div>
                          <div className="l-details mt-3">
                             <p>{this.state.news0.title}</p>
                          </div>
                       </Link>
                    </li>
                </ul>
         </div>  
          )
        }  
        else {
          return ''
        }
        })()}
 

 <div className="meganav__item">
                <ul className="mega-news mega-news--sub">
                {(() => {
        if (this.state.news1) {
          return (
                      <li className="mega-news__item">
                        
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1.name}>
                          <div className="l-details">
                               <p>{this.state.news1.title}</p>
                          </div>
                        </Link>
                      </li>
   )
  }  
  else {
    return ''
  }
  })()}

{(() => {
        if (this.state.news2) {
          return (
                      <li className="mega-news__item">
                        <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2.name}>
                            <div className="l-details mt-3">
                                <p>{this.state.news2.title}</p>
                            </div>
                        </Link>
                      </li>

)
}  
else {
  return ''
}
})()}


{(() => {
        if (this.state.news3) {
          return (
                      <li className="mega-news__item">
                       <Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3.name}>
                            <div className="l-details mt-3">
                                <p>{this.state.news3.title}</p>
                            </div>
                        </Link>
                      </li>

)
}  
else {
  return ''
}
})()}
                  </ul>
            </div> 


        
           
        </div>
    </div>
    }
    else

    return  ""

}
}
export default Menulist;
