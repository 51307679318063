import React from 'react';
import Recommends from '../Components/Recommends';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Breadcrumbs from '../Components/Breadcrumbs';
import ReactPaginate from "react-paginate";
class   Search extends React.Component {
  constructor(){
    super();
    this.state = {
      
        dat:[],srch:[],
        offset:0,
        tableData:[],
        orgtableData:[],
        perPage:10,
        currentPage:0,
        pageCount:1,check:false
        
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  
}
handlePageClick = (e) =>{
  const selectedPage = e.selected;
  const offset = selectedPage * this.state.perPage;

  this.setState({
     currentPage:selectedPage,
     offset:offset

  },() => {
    this.loadMoreData()
  });
  
};


loadMoreData(){
  const data = this.state.orgtableData;

  const slice = data.slice(this.state.offset,this.state.offset + this.state.perPage)
   this.setState({
       pageCount:Math.ceil(data.length / this.state.perPage),
       tableData:slice
   })
}

componentDidMount(){
  this.getData();
}

getData(){
        const id = this.props.match.params.slug5
        this.setState({srch:id})
        this.setState({check:true})
        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?search='+id), {
          headers : {'content-type': 'application/json'}
      })
      .then(res=>{
          this.setState({check:false})
          this.setState({dat:res.data['message']})
          //console.log(response.data['message'])
          //console.log(response.data['data'])
          var data=res.data['message']
          var slice= data.slice(this.state.offset,this.state.offset+ this.state.perPage)
        
        this.setState({
        // pageCount:Math.cell(data.length / this.state.perPage),
          orgtableData:res.data['message'],
          tableData:slice
        })
        this.setState({
          pageCount:Math.ceil(data.length / this.state.perPage)
        
      })
      
      })
      .catch(
          err =>{

            this.setState({check:false})
             console.log(err)}
          
        )
      }


render() {
return <div>
    <div className="container">
  <div className="row">
          <div className="col-sm-12">
          {/*   <!-- news starts--> */}
          <Breadcrumbs parent={this.state.srch}  />
          <div className="news">
          {(() => {

if (this.state.dat.length) {
  return ( <ul className="news__list top-story_news ">
  { this.state.tableData.map((itm,j)=>{
      var stripedHtml=itm.main_section.replace(/(<([^>]+)>)/gi,"");
      this.content = stripedHtml.slice(0,425)
      

      return(<li key={j} className="news__item">  <Link to={process.env.PUBLIC_URL +'/news/'+itm.name}className="l-thumb">
          <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="top-story-img" width="150" height="120"  alt="" title=""/>
          </Link>
          <div className="l-details news-dtls">
          <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
          <p>{this.content }...</p>
          </div>
      </li>)

      })}
      

  </ul>)
} 
        
else {
  return (<h3 className="text-center">{this.state.check ? " Searching.. " : "No Stories Found"}</h3>)
}
})()}
 <div className={this.state.dat.length ? 'd-flex justify-content-center' : 'd-none' }>
               <ReactPaginate 
               previousLable={"prev"}
               nextLable={"next"}
               breakLabel={"..."}
               breakClassName={"break-me"}
               pageCount={this.state.pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={5}
               onPageChange={this.handlePageClick}
               containerClassName={"pagination"}
               subContainerClassName={" pages pagination"}
               activeClassName={"active"}
               /> 
           </div>
                   
           </div>
           
</div>
<div className="col-sm-4 ">
                <Recommends />
         </div>
</div>
</div>
</div>
}

}
export default  withRouter(Search);