import React from 'react';
import axios from 'axios';
import Recommends from '../Components/Recommends';
import { withRouter } from "react-router";
import Group from '../Components/Group';
import List from '../Components/List';
class Menunews extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        news : [],check:0,item:[]
    }
}
getData(parent){
  //const parent = this.props.match.params.slug4
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.subMenu?parentRoute='+parent), {
    headers : {'content-type': 'application/json'}
  })
  .then(response=>{
    if(response.data.message['items'] && response.data.message['items'].length > 0){
      this.setState({check:1})
    }
    else{
      this.setState({check:0})
    }
    this.setState({item:parent})
    
  })
  .catch(err=>{ console.log(err)})
}
componentDidMount(){
  if(this.props.match.params.slug3){
      this.getData(this.props.match.params.slug3)
  }
  else{
      this.getData(this.props.match.params.slug4)
  }
  
}

componentDidUpdate(prevProps){

  if(this.props.match.params.slug3){
    if(prevProps.match.params.slug3 !== this.props.match.params.slug3){
        this.getData(this.props.match.params.slug3) 
    }
  }
  else{
    if(prevProps.match.params.slug4 !== this.props.match.params.slug4){
      this.getData(this.props.match.params.slug4) 
    }

  }
  
}
render() {
  
    return <div>
    <div className="container">
      <div className="row">
      
      {this.state.check ? <Group cate={this.props.match.params.slug4}/> : <List cate={this.props.match.params.slug4}/>}      

          <div className="col-sm-4 ">
            <Recommends />
            
          </div>
        </div>
      </div>

          </div>

  
  
    
    }
    }
    export default withRouter(Menunews);