import React from 'react';

import Topheader from './Topheader';
import Fullwidth from './Fullwidth';
import Advsmall from './Advsmall';
import Breakingnews  from '../Components/Breakingnews';
import Menu from '../Components/Menu';
import Headerdate from '../Components/Headerdate';
class Header extends React.Component {
  render() {
return <div>
    < Topheader/>
      <div className="container">
          <Fullwidth zone="ZNE001"/>
      </div>
      <div className="container">
      <div className="header">
          <div className="header__block"><a href="/index" className="header__logo"><img src="/images/logo.gif" style={{maxHeight: '95px'}} alt="British Malayali" title="British Malayali"/></a>
            <Advsmall id="ZNE002"/>
            
            <Headerdate />
          </div>
          {/* <!-- mainnav starts--> */}
        <Menu/>
         {/*  <!-- mainnav ends--> */}
         <Breakingnews />
        </div>
    </div>
     </div>
     


}
}
export default Header;