import React from 'react';
import Searchbar from './Searchbar';
class Topheader extends React.Component {
  render() {
return <div>
    <section className="section-topbar">
        <div className="container">
          <div className="topbar"> 
            <ul className="topbar__links">
              <li><a href="https://apps.apple.com/in/app/britishmalayali/id593031102 " rel="noreferrer" target="_blank" ><i className="fa fa-apple"></i></a></li>
              <li><a href="https://play.google.com/store/apps/details?id=com.azinova.britishmalayali&feature=nav_result#?t=W251bGwsMSwyLDNd" rel="noreferrer" target="_blank"><i className="fa fa-android"></i></a></li>
              <li><a href="https://www.facebook.com/britishmalayali.co.uk?fref=ts"target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
              
              {/* <li><a href="https://twitter.com/marunadannews"><i className="fa fa-twitter"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCpp_oKgF30vkhF1WDcX64mg"><i className="fa fa-play-circle-o"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCBeF-3nSRWwsGttfAA3WSvA"><i className="fa fa-youtube"></i></a></li> */}
              
            </ul>
           {/*  <Forexrate/> */}
            <ul className="topbar-nav d-none d-lg-flex">
              <li className="topbar-nav__item"><a href="/aboutus.html">About Us</a></li>
              <li className="topbar-nav__item"><a href="/contactus.html">Contact Us</a></li>
              <li className="topbar-nav__item"><a href="/grievance-redressal.html">Grievance</a></li>
              <li className="topbar-nav__item"><a href="#0">Post your News</a></li>
              <li className="topbar-nav__item"><a href="#0">Download Font</a></li>
              <li className="topbar-nav__item"><a href="#0">Marunadan Malayali</a></li>
            </ul>
            <Searchbar/>
          </div>
         
        </div>
      </section>
    
      </div>
      }
    }
    export default Topheader;