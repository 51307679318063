import React from 'react';


class  Breadcrumbs extends React.Component {
  constructor(props){
    super(props);
        this.state = {
          category:[],parent:[]
        }
}
 
 render() {

if(this.props.category)
return <div>   
          <div className="breadcrumb">
            <a className="breadcrumb__item" href="/index"> <span>ഹോം</span></a>
            <a className="breadcrumb__item" href={'/'+this.props.caturl}> <span>{this.props.category}</span></a>
            <a className="breadcrumb__item breadcrumb__item--is-current" href={'/'+this.props.parurl}> <span>{this.props.parent}</span></a>
          </div> 
</div>
else
return <div>   
          <div className="breadcrumb">
            <a className="breadcrumb__item" href="/index"> <span>ഹോം</span></a>
            <a className="breadcrumb__item breadcrumb__item--is-current" href={this.props.parurl ? '/'+this.props.parurl : "#0"}> <span>{this.props.parent}</span></a>
           
          </div> 
</div>
  }
}


export default Breadcrumbs;