
import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Ads300x600 from '../Components/Ads300x600';
import Ads300x90 from './Ads300x90';



class Topstory extends React.Component {
    constructor(props){
        super(props);
        this.state = {
           title: [],  title1: [],  title2: [],  title3: [],  title4: [], title5: [], title6: [],  title7: [],
          content: [],content1: [],content2: [],content3: [],content4: [], content5: [], content6: [], content7: [],
          image: [],image1: [],image2: [],image3: [],image4: [], image5: [], image6: [],image7: [],
          sty:[],name1:[],name2:[],name3:[],
          lnews:undefined
        }
    }
    componentDidMount(){


        
        this.setContent()


    }
    setContent(){
        if(this.props.news.length>0){
            this.setState({title1:this.props.news[0]['title']})
            this.setState({title2:this.props.news[1]['title']})
            this.setState({title3:this.props.news[2]['title']})
            this.setState({title4:this.props.news[3]['title']})
            this.setState({content1:this.props.news[0]['main_section']})
            this.setState({content2:this.props.news[1]['main_section']})
            this.setState({content3:this.props.news[2]['main_section']})
            this.setState({content4:this.props.news[3]['main_section']})
            this.setState({image1:this.props.news[0]['cover_photo']})
            this.setState({image2:this.props.news[1]['cover_photo']})
            this.setState({image3:this.props.news[2]['cover_photo']})
            this.setState({image4:this.props.news[3]['cover_photo']})
            this.setState({name1:this.props.news[0]['name']})
            this.setState({name2:this.props.news[1]['name']})
            this.setState({name3:this.props.news[2]['name']})
            this.setState({name4:this.props.news[3]['name']})

            var stripedHtml=this.props.news[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
            this.setState({content1:stripedHtml.slice(0,425)})
            stripedHtml=this.props.news[1]['main_section'].replace(/(<([^>]+)>)/gi,"");
            this.setState({content2:stripedHtml.slice(0,425)})
            stripedHtml=this.props.news[2]['main_section'].replace(/(<([^>]+)>)/gi,"");
            this.setState({content3:stripedHtml.slice(0,425)})
            stripedHtml=this.props.news[3]['main_section'].replace(/(<([^>]+)>)/gi,"");
            this.setState({content4:stripedHtml.slice(0,425)})
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.lnews !== this.props.lnews){
            this.setState({lnews:this.props.lnews})
        }
        if(prevProps.news !== this.props.news){
            this.setContent()
        }
        
    }
render() {
  
return <div>
 <div className="row top-story">
       <div className="col-sm-12">
            <div className="news">
                    <ul className="news__list top-story_news ">
                    { this.props.news.map((itm,j)=>{
                        var stripedHtml=itm.main_section.replace(/(<([^>]+)>)/gi,"");
                        this.content = stripedHtml.slice(0,425)
                        

                        return(<li key={j} className="news__item">  <Link to={process.env.PUBLIC_URL +'/news/'+itm.name}className="l-thumb2">
                            <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="top-story-img" width="150" height="120"  alt="" title=""/>
                             <div className="l-date2"> <Moment format="MMMM DD / YYYY" withTitle>{itm.start_date}
                            </Moment></div> 
                           
                            </Link>
                            <div className="l-details news-dtls">
                            <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
                            <p>{this.content }...</p>
                            </div>
                        </li>)

                        })}
                        

                    </ul>
           </div>

           <div className="adZNE024"> <Ads300x90 id="ZNE024" /> </div>
        </div>
        <div className="col-sm-4">
                <Ads300x600 id="ZNE005"/>   
               
                
       
       </div>







    </div>

</div>
}
}
export default Topstory;