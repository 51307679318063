
import React from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Ads300x250 from '../Components/Ads300x250';
class   Style7 extends React.Component {
  constructor(){
    super();
    this.state = {
      news0: [],
        news1 : [],
        news2 : [],
        news3 : [],
        news4 : [],
        news5 : [],content0:[],content1:[],content2:[],content3:[],content4:[],content5:[]

        
        
    }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route=The movie&limit=6&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
})
.then(res=>{
  if(res.data['message'][0])
  {
    this.setState({news0:res.data['message'][0]})
    var stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content0:stripedHtml.slice(0,200)})
  }
  if(res.data['message'][1])
  {
    this.setState({news1:res.data['message'][1]})
    stripedHtml=res.data.message[1]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content1:stripedHtml.slice(0,200)})
  }
  if(res.data['message'][2])
  {
    this.setState({news2:res.data['message'][2]})
    stripedHtml=res.data.message[2]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content2:stripedHtml.slice(0,200)})
  }
  if(res.data['message'][3])
  {
    this.setState({news3:res.data['message'][3]})
  stripedHtml=res.data.message[3]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content3:stripedHtml.slice(0,200)})
  }
  if(res.data['message'][4])
  {
    this.setState({news4:res.data['message'][4]})
     stripedHtml=res.data.message[4]['main_section'].replace(/(<([^>]+)>)/gi,"");
    this.setState({content4:stripedHtml.slice(0,200)})
  }
  if(res.data['message'][5])
  {
    this.setState({news5:res.data['message'][5]})
     stripedHtml=res.data.message[5]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content5:stripedHtml.slice(0,200)})
  }
 
  
 
  
 
  
 // console.log(res.data['message'][0])
  
})

.catch(
  err =>{ console.log(err)}
)
}
  render() {
return <div>
   <div className="row">
          <div className="col-16">
            <div className="news">
              <div className="l-title-block">
                <h2 className="l-title l-title--success"><span>സിനിമ</span></h2>
                <ul className="l-title-nav">
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Channel Links</a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Reviews </a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Serial Shows</a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Interviews</a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Channel Links</a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#">Prime Shows</a></li>
                  <li className="l-title-nav__item"><a className="l-title-nav__link" href="/#"><i className="fa fa-bars"></i></a></li>
                </ul>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <ul className="news__list">
                    <li className="news__item d_block_res"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=230&h=265'}alt="Loading..." title="Loading..." /> */}
                    <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="styl4-char-img" width="230px" height="265px"  alt="Loading..." title="Loading..."/></Link>
                      <div className="l-details news-dtls4">
                         <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}>{this.state.news0['title']}</Link></h3>
                    <div> {this.state.content0}...</div>
                      </div>
                    </li>
                     <li className="news__item d_block_res"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=230&h=265'}alt="Loading..." title="Loading..." /> */}</Link>
                     <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="styl4-char-img" width="230px" height="265px"  alt="Loading..." title="Loading..."/>
                      <div className="l-details news-dtls4">
                         <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}>{this.state.news1['title']}</Link></h3>
                    <div> {this.state.content1}...</div>
                      </div>
                    </li> 
                  </ul>
                </div>
                <div className="col-sm-6">
                  {/* <!-- news starts--> */}
                  <div className="news">
                    <ul className="news__list">
                       <li className="news__item"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                       <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/></Link>
                        <div className="l-details ">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news2['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                           <div> {this.state.content2}...</div>
                        </div>
                      </li> 
                      <li className="news__item"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news3['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                      <img src={ process.env.REACT_APP_API_URL+this.state.news3['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/></Link>
                        <div className="l-details ">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news3['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news3['start_date']}</Moment></div>
                           <div> {this.state.content3}...</div>
                        </div>
                      </li> *
                       <li className="news__item"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news4['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news4['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                       <img src={ process.env.REACT_APP_API_URL+this.state.news4['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/></Link>
                        <div className="l-details ">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news4['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news4['start_date']}</Moment></div>
                           <div> {this.state.content4}...</div>
                        </div>
                      </li> 
                       <li className="news__item"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news5['name']}className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news5['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                       <img src={ process.env.REACT_APP_API_URL+this.state.news5['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/></Link>
                        <div className="l-details ">
                        <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news5['start_date']}
                        </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news5['start_date']}</Moment></div>
                           <div> {this.state.content5}...</div>
                        </div>
                      </li> 
                    </ul>
                  </div>
                  {/* <!-- news ends--> */}
                </div>
                <div className="col-sm-4">
                 
                 <Ads300x250/>
                  <Ads300x250/>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>
}
}
export default Style7;