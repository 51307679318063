
import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Ads300x250 from '../Components/Ads300x250';
import Adshorizontal from './Adshorizontal';
class   Style6 extends React.Component {
  constructor(){
  super();
  this.state = {
    news0: [],
      news1 : [],
      
      
      
  }
}
componentDidMount(){
  axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit='+this.props.limit+ '&start=' +this.props.start), {
    headers : {'content-type': 'application/json'}
})
.then(res=>{
this.setState({news0:res.data['message'][0]})
this.setState({news1:res.data['message'][1]})


//console.log(res.data['message'][0])
})

.catch(
  err =>{ console.log(err)}
)
}
  render() {
return <div>
  <div className="row">
          <div className="col-16">
                    <h2 className={'l-title l-title--'+this.props.class}><span>{this.props.head}</span></h2>
            <div className="row">
              <div className="col-sm-6">
                <div className="news">
                  <div className="news__item">
                    <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                    <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/>
                    </div>
                    <div className="l-details news-dtls5">
                    <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news0['start_date']}
                    </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news0['start_date']}</Moment></div>
                      <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}><ReactHtml html={this.state.news0['title']}/></Link></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="news">
                  <div className="news__item">
                    <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=150&h=130'}alt="Loading..." title="Loading..." /> */}
                    <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="styl4-mam-img" width="150px" height="130px"  alt="Loading..." title="Loading..."/>
                    </div>
                    <div className="l-details news-dtls5">
                    <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news1['start_date']}
                    </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news1['start_date']}</Moment></div>
                      <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}><ReactHtml html={this.state.news1['title']}/></Link></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
              <Ads300x250 id={this.props.ads}/>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
                  <div className="col-sm-16 d-flex justify-content-center">
                          <Adshorizontal id={this.props.bottomAds}/>
                  </div>
              </div>
</div>
}
}
export default Style6;