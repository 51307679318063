import React from 'react';

import Menulist from '../Components/Menulist';
import axios from 'axios';
import { Link } from 'react-router-dom';
class Menu extends React.Component {
  constructor(){
    super();
    this.state = {
        men : [],title : [], opacity: 0,  mmenu:'mainnav__list ', toggle:false
    }
    
}
componentDidMount(){
/*
axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.mainMenu'), {
    headers : { 'content-type': 'application/json'}
   
})
.then(response=>{
    this.setState({men:response.data['message']})
    console.log(response.data['message']) 
    
})  
.catch(error=>{console.log(error)})
*/
axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getMenu'), {
  headers : { 'content-type': 'application/json'}
 
})
.then(response=>{
  this.setState({men:response.data['message']})
  //console.log(response.data['message']) 
  
})  
.catch(error=>{console.log(error)})




}
showMenu = async e => {
  e.preventDefault();
  if (this.state.toggle){
      this.setState({mmenu:'mainnav__list '})
      this.setState({toggle:false})
  }
  else{
    this.setState({mmenu:'mainnav__list mobile-menu'})
    this.setState({toggle:true})

    //await delay(1000);
    
  }
 
}
clickLink = async e => {
  e.preventDefault();
      this.setState({mmenu:'mainnav__list '})
      this.setState({toggle:false})

}
  render() {
return <div>
     <div className="mainnav">
            <button className="l-nav-toggle" onClick={this.showMenu}><i className="fa fa-bars" aria-hidden="true"></i><span>Menu</span></button>
            <ul className={this.state.mmenu} >
              <li className="mainnav__item"><a className="mainnav__link active" href="/index.html"><i className="fa fa-home"></i></a></li>
              {this.state.men.map((itm,k)=>{
              return(<li key={k} className="mainnav__item has-dropdown" onClick={this.clickLink}><Link to={process.env.PUBLIC_URL + '/'+itm.route} className="mainnav__link">{itm.category_name}</Link>               
                  <Menulist groups={itm.route} subs={itm.subs}/>
              </li>)})} 
            </ul>
          </div>
      </div>
    }
}
export default Menu;