import React from 'react';
import Moment from 'react-moment';
class Latestnews extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          
          sty:[]
        }
    }
    componentDidMount(){
        
        //console.log("Latest News")
        //console.log(this.props.lnews)
        if(this.props.lnews){
            this.setState({sty:this.props.lnews})
        }
        
    }
    render() {
        
        return <div>
                    <div className="latest-news">
                        <h3 className="l-title"><span>Latest News</span></h3>
                        <ul className="latest-news__list">
                        {this.state.sty.map((itm,k)=>{
                            
                            return(
                                    <li key={k} className="latest-news__item">
                                    <p><a href={itm.url} target="_blank" rel="noreferrer" >{itm.title.slice(0,100) + ''}<span className="l-posted"> <Moment fromNow>{itm.datetime}</Moment></span></a></p>
                                    </li>
                                    )
                                })} 
                        </ul>
                        <div className="mt-3"> <a href="https://www.marunadanmalayalee.com/latest" target="_blank" rel="noreferrer" >Read More...</a></div>
                        </div>
                </div>
                }
    }
    export default Latestnews;
                    