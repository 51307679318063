import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import  './List.css';
import ReactPaginate from "react-paginate";
import Fullwidth from './Fullwidth';
class   List extends React.Component {
  constructor(){
    super();
        this.state = {
            lis: [],title:[],arc:[],
          offset:0,
          tableData:[],
          orgtableData:[],
          perPage:10,
          currentPage:0,
          pageCount:1


             
        }
        this.handlePageClick = this.handlePageClick.bind(this);
}
 handlePageClick = (e) =>{
   const selectedPage = e.selected;
   const offset = selectedPage * this.state.perPage;

   this.setState({
      currentPage:selectedPage,
      offset:offset

   },() => {
     this.loadMoreData()
   });
   
 };
 loadMoreData(){

  
     const data = this.state.orgtableData;

     const slice = data.slice(this.state.offset,this.state.offset + this.state.perPage)
      this.setState({
          pageCount:Math.ceil(data.length / this.state.perPage),
          tableData:slice
      })
 }

componentDidMount(){
       this.getData();
}
componentDidUpdate(prevProps){
  if(prevProps.cate !== this.props.cate){
      this.getData()
  }
}





  getData(){
    //alert(this.props.cate)
   axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getNewsList?route='+this.props.cate), {
    headers : {'content-type': 'application/json'}
            })
            .then(
              res=>{ 
               
                this.setState({title:res.data.message[0]['category_name']})
                this.setState({lis:res.data['message']})
                var data=res.data['message']
                var slice= data.slice(this.state.offset,this.state.offset+ this.state.perPage)
              
              this.setState({
               // pageCount:Math.cell(data.length / this.state.perPage),
                orgtableData:res.data['message'],
                tableData:slice
              })
              this.setState({
                pageCount:Math.ceil(data.length / this.state.perPage)
              
            })



             }
              
            )
            .catch(
              err =>{ console.log(err)}
            )
          
            

            axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/resource/News Category?filters=[["route","=","'+this.props.cate+'"]]&fields=["name","category_name","archive_link","route"]&limit_start=0&limit_page_length=10000'), {
              headers : {'content-type': 'application/json'}
          })
          .then(response=>{
              this.setState({arc:response.data.data[0]['archive_link']})
              //console.log(response.data['data'])
            
           
          })
          .catch(
              err =>{ console.log(err)}
            )

          }

    
 render() {
return <div className="col-sm-12">
        
            {/* <!-- news starts--> */}
    
            <h2 className="l-title l-title--info"><span>{this.state.title}</span></h2>
                  
                
            <div className="news">
            <ul className="news__list">
            {this.state.tableData.map((itm,k)=>{
                      if(itm.main_section){
                          var stripedHtml=itm.main_section.replace(/(<([^>]+)>)/gi,"");
                          
                            if (k===4 || k===9 ) {
                              return(<>
                        
                                <li key={k} className="news__item d_block_res "><Link to={process.env.PUBLIC_URL +'/news'+itm.route} className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+itm.cover_photo+'?func=crop&w=150&h=120'}alt="Loading..." title="Loading..." /> */}
                                <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="top-story-img" width="150px" height="120px"  alt="Loading..." title="Loading..."/></Link>
                                    <div className="l-details news-dtls ">
                                      <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
                                      <p>{stripedHtml.slice(0,425)}</p>
                                    </div>
                                    
                                  </li>
                                  <div className=" mt-5"> 
                                  <Fullwidth zone="ZNE021"/>
                              </div></>)
                            }
                            else{
                              return(
                                <li key={k} className="news__item d_block_res "><Link to={process.env.PUBLIC_URL +'/news'+itm.route} className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+itm.cover_photo+'?func=crop&w=150&h=120'}alt="Loading..." title="Loading..." /> */}
                                <img src={ process.env.REACT_APP_API_URL+itm.cover_photo} className="top-story-img" width="150px" height="120px"  alt="Loading..." title="Loading..."/></Link>
                                    <div className="l-details news-dtls ">
                                      <h3><Link to={process.env.PUBLIC_URL +'/news/'+itm.name}>{itm.title}</Link></h3>
                                      <p>{stripedHtml.slice(0,425)}</p>
                                    </div>
                                    
                                  </li>)

                            }
                            
                         


                            
                        }
                        else
                          return null
                })} 
                 </ul>
                 </div>
              <div className="d-flex justify-content-center">
               <ReactPaginate 
               previousLable={"prev"}
               nextLable={"next"}
               breakLabel={"..."}
               breakClassName={"break-me"}
               pageCount={this.state.pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={5}
               onPageChange={this.handlePageClick}
               containerClassName={"pagination"}
               subContainerClassName={" pages pagination"}
               activeClassName={"active"}
               /> 
           </div>

                <p className="l-details text-right">
                 {/*  { itm.archive_link ?  <a href="#"><b>Click here to see more  from Archives »</b></a> :  ''} */}
                 
                 { this.state.arc ?  <a href={this.state.arc} target="_blank" rel="noreferrer"  ><b>Click here to see more  from Archives »</b></a> :  ''}
                 </p>
              </div>
     



    }
}
export default List;
              
              
             




        
           
            





        
                

                
                

    



