import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Marquee from "react-fast-marquee";
class Breakingnews extends React.Component {
   constructor(){
    super();
    this.state = {

     
      sty:[]
    }
}
componentDidMount(){
    axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/resource/Breaking News?filters=[["enabled", "=", 1]]&fields=["news_scroll","start_date","link"]&order_by=start_date desc'), {
        headers : {'content-type': 'application/json'}
                })
                .then(response=>{
                  this.setState({sty:response.data.data})
                 // console.log(response.data.data)
              })

            }
  render() {
            if(this.state.sty.length>0){
                return <div>
                    <div className="break-news">
                            <div className="break-news__title"><span>Breaking News</span></div>
                            <div className="break-news__wrap">
                            <Marquee pauseOnHover="false" gradient="false">

                        <ul className="break-news__ticker">
                        {this.state.sty.map((itm,k)=>{
                                                            return(
                              <li key={k}  className="break-news__item"><a rel="noreferrer"  href={itm.link} target="_blank" className="break-news__link" ><ReactHtml html={itm.news_scroll}/></a><span>||</span></li>
                              )
                              })}
                              </ul>
                              </Marquee>
                            </div>
                          </div>
                </div>
            }
            else
              return null
  }
}
export default Breakingnews;