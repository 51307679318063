
import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';

import { Link } from 'react-router-dom';

class   Inke extends React.Component {
    constructor(){
      super();
      this.state = {
        news0: [],
          news1 : [],
          news2 : []
        
  
          
          
      }
  }
  componentDidMount(){
    axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit=3'), {
      headers : {'content-type': 'application/json'}
  })
  .then(

    res=>{ 
      if(res.data['message'].length >2 ){
        this.setState({news0:res.data['message'][0]})
        this.setState({news1:res.data['message'][1]})
        this.setState({news2:res.data['message'][2]})
        

      }
      
  
      }
  )
  .catch(
    err =>{ console.log(err)}
  )
 
  
  
  
  }
  render() {
    
    return       <div className="news">

              <h2 className={'l-title '+this.props.class}><span>{this.props.head}</span></h2>
              <ul className="news__list">
                <li className="news__item">
                  <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news3['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news0['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                  </div>
                  <div className="l-details news-dtls3">
                  <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news0['start_date']}
                    </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news0['start_date']}</Moment></div>
                      <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news0['name']}><ReactHtml html={this.state.news0['title']}/></Link></div>
                  </div>
                </li>
                <li className="news__item">
                  <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news1['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                  </div>
                  <div className="l-details news-dtls3">
                  <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news1['start_date']}
                    </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news1['start_date']}</Moment></div>
                      <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news1['name']}><ReactHtml html={this.state.news1['title']}/></Link></div>
                  </div>
                </li>
                <li className="news__item">
                  <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                  <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                  </div>
                  <div className="l-details news-dtls3">
                  <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news2['start_date']}
                    </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                      <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}><ReactHtml html={this.state.news2['title']}/></Link></div>
                  </div>
                </li>
              </ul>
            </div>
            }
        }
        export default Inke;