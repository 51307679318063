import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import Recommends  from '../Components/Recommends';
import { withRouter } from "react-router";
import Breadcrumbs from '../Components/Breadcrumbs';
import { Link } from 'react-router-dom';
import Mostread from '../Components/Mostread';
import Ads300x90 from '../Components/Ads300x90';
import HelmetMetaData from "../Components/HelmetMetaData";

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookShareCount,
    FacebookMessengerShareButton,
    LinkedinShareButton,
     RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
class   Mainnews extends React.Component {
  constructor(){
    super();
    this.state = {
pName:[],imgPos:[],


news: [],title:[],content:[],author:[],byline:[],image:[],parentname:[],categoryname:[],name:[],cat : [], par:[], auth:'author'
    
    }
}






getData(){
    const query = new URLSearchParams(this.props.location.search);

    const token = query.get('id')
    //console.log(token)//123

    const id = this.props.match.params.slug2
    if(token){
        // Simulate a mouse click:
        window.location.href = "http://old.britishmalayali.co.uk/index.php?page=newsDetail&id=" + token;
        /*axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNews?oldID=')+token, {
            headers : {'content-type': 'application/json'}
                    })
                    .then(response=>{
                        this.setState({title:response.data.message['title']})
                        this.setState({pName:response.data.message['name']})
                        this.setState({content:response.data.message['main_section']})
                        this.setState({image:response.data.message['cover_photo']})    
                        this.setState({news:response.data.message['start_date']})
                         this.setState({categoryname:response.data.message['parent_category_name']})
                          this.setState({parentname:response.data.message['category_name']}) 
                          this.setState({name:response.data.message['by_line']}) 
                          this.setState({author:response.data.message['author_name']}) 
                          this.setState({byline:response.data.message['by_line_name']}) 
                          this.setState({par:response.data.message['category_route']}) 
                          this.setState({cat:response.data.message['parent_category_route']}) 
                          this.setState({imgPos:response.data.message['photo_position']})
                          //this.setState({name:response.data.message['by_line']})
                    })*/
    }
    else{
        axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNews?name=')+id, {
            headers : {'content-type': 'application/json'}
                    })
                    .then(response=>{
                        this.setState({title:response.data.message['title']})
                        this.setState({pName:response.data.message['name']})
                        this.setState({content:response.data.message['main_section']})
                        this.setState({image:response.data.message['cover_photo']})    
                        this.setState({news:response.data.message['start_date']})
                         this.setState({categoryname:response.data.message['parent_category_name']})
                          this.setState({parentname:response.data.message['category_name']}) 
                          this.setState({name:response.data.message['by_line']}) 
                          this.setState({author:response.data.message['author_name']}) 
                          this.setState({byline:response.data.message['by_line_name']}) 
                          this.setState({par:response.data.message['category_route']}) 
                          this.setState({cat:response.data.message['parent_category_route']}) 
                          this.setState({imgPos:response.data.message['photo_position']})
                          //this.setState({name:response.data.message['by_line']})
                    })

                    let nid ={
                      'nodeid':id
                    }
                    axios.post(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.logNewsView'),nid, {
                      headers : {'content-type': 'application/json'}
                              })
                              .then(response=>{
                                console.log("read")
                              })
          
        

    }
    

}



async componentDidMount(){
    
 this.getData()
 if (window.FB) {
    window.FB.XFBML.parse();
    }
                     
}

componentDidUpdate(prevProps){
    if(prevProps.match.params.slug2 !== this.props.match.params.slug2){
        this.getData()
    } 
}


render() {
    const shareUrl = 'https://www.britishmalayali.co.uk/news/'+this.state.pName;
    const title = this.state.title;


    var target = this.state.content.toString().replace(/\/files/g, "https://desk.britishmalayali.co.uk/files/").replace(/&quot;/ig, '').replace(/\/\/britishmalayali.co.uk/ig, '//www.britishmalayali.co.uk');

    return <div>
    <HelmetMetaData 
        title={this.state.title } 
        description={this.state.title } 
        image={process.env.REACT_APP_API_URL+this.state.image}>
    </HelmetMetaData>


<div className="container">
    <div className="row">
        <div className="col-sm-12">
            <Breadcrumbs category={this.state.categoryname} parent={this.state.parentname} caturl={this.state.cat} parurl={this.state.par} />




                <div className="news-detail">
                    <Ads300x90 id="ZNE016" />


                    <h3 className="mt-2">{this.state.title}</h3>
                        <div className="l-meta l-meta--hrz">
                        <a href="#0"><Moment format="MMMM DD , YYYY" withTitle>{this.state.news}</Moment></a>
                          <span><Moment format="h:MM A"withTitle>{this.state.news}</Moment></span> 
                          <Link to={process.env.PUBLIC_URL +'/news/'+this.state.pName}>Permalink</Link>
                        </div>
                        
                        {(() => {

        if (this.state.imgPos === "Left Align") {
          return (
            <div className="l-thumb l-thumb--bottom half-wth-frmt">
                            <div className="cphoto-top">
                                <img src={process.env.REACT_APP_API_URL+this.state.image}alt="Loading..." title="Loading..."  width="100%"/>  
                            </div>           
                        </div>
          )
        } 
        
        else {
          return (
            <div className="l-thumb l-thumb--bottom">
                            <div className="cphoto-top">
                                <img src={process.env.REACT_APP_API_URL+this.state.image}alt="" title=""  width="100%"/>  
                            </div>           
                        </div>
          )
        }
      })()}


                        
                        <div className="">
                            <p className="l-meta-author 1">
                             { this.state.name === 'By Line'? <Link to={process.env.PUBLIC_URL +'/byline/'+this.state.byline}> {this.state.byline} </Link>: <Link to={process.env.PUBLIC_URL +'/byauthor/'+this.state.author}> {this.state.author} </Link> } 
                             </p> 
                            <div className="l-main-details">
                                <ReactHtml html={target}/>
                            </div>
                        </div>
                        <Ads300x90 id="ZNE031" />
                        
            <div
                className="fb-comments"
                data-href={shareUrl}
                data-width="100%"
                data-numposts="5"
            ></div>
           
           <div>
            <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
              {count => count}
            </FacebookShareCount>
          </div>

           
            <div>
            <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
            </FacebookShareButton>
          
            <FacebookMessengerShareButton
            url={shareUrl}
            appId="665674156872122"
            className="Demo__some-network__share-button"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
            
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
              
            </div>

            <div className="fb-like mt-5 mb-5" data-href="https://www.facebook.com/britishmalayali.co.uk" data-width="" data-layout="standard" data-action="like" data-size="small" data-share="true"></div>


            <Ads300x90 id="ZNE019" />

                    
          </div>
      

        </div>
        <div className="col-sm-4 ">
            <Recommends />   
            <Mostread />       
        </div>

                    
    </div>
</div>



    </div>
    }
}
export default withRouter(Mainnews);





