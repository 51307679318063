import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
class   Fullwidth extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            ad:undefined
        }
    }
    componentDidMount(){
          this.getContent();
    }
    componentDidUpdate(prevProps){
      if(prevProps.zone !== this.props.zone){
          this.getContent();
      }

    }
    getContent(){
      if(this.props.zone){
            axios.get(encodeURI(process.env.REACT_APP_API_URL+'api/method/bm.api.getAd?zone=' + this.props.zone), {
              headers : {'content-type': 'application/json'}
            })
            .then(
                res=>{ this.setState({ad:res.data['message'][0]})
                //console.log(res.data['message'][0])
            })
            .catch(
                err =>{ console.log(err)}
              )
            }
    }

    render() {
        if(this.state.ad){
          return <div>
          <div className="l-ad-wrap mb-2">
              {(() => {
                  if (this.state.ad['campaign_type'] === 'Graphic Banner') {
                    return ( <a target="_blank" rel="noreferrer"  href={this.state.ad['redirect_url']}>
                    <img src={process.env.REACT_APP_API_URL+this.state.ad['banner_image']}  alt="advertisement" title="advertisement"/> </a>)
                    }
                  else{
                    return ( <ReactHtml html={this.state.ad['ad_html_code']}/>)
                  }
              })()}
              
          </div>
        </div>

        }
        else{

          return <> </>
        }
        
      
    }
    
}
export default Fullwidth;