
import React from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class   Tabitems extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      news: [],
      content0:[],
      content1:[],
      news0: [],
      news1 : [],
      news2 : [],
      news3 : [],
      news4 : [],
      news5 : [],
      news6: [],
      news7: []

        
        
    }
}
componentDidMount(){
    axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.getNewsList?route='+this.props.cat+'&limit='+this.props.limit), {
        headers : {'content-type': 'application/json'}
})
.then(res=>{
  this.setState({news:res.data['message']})
  this.setState({news0:res.data['message'][0]})
  this.setState({news1:res.data['message'][1]})
  this.setState({news2:res.data['message'][2]})
  this.setState({news3:res.data['message'][3]})
  this.setState({news4:res.data['message'][4]})
  this.setState({news5:res.data['message'][5]})
  this.setState({news6:res.data['message'][6]})
  this.setState({news7:res.data['message'][7]})
  var stripedHtml=res.data.message[0]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content0:stripedHtml.slice(0,200)})
  var stripedHtml2=res.data.message[1]['main_section'].replace(/(<([^>]+)>)/gi,"");
  this.setState({content1:stripedHtml2.slice(0,200)})
  
  //console.log(res.data['message'][0])
})

.catch(
  err =>{ console.log(err)}
)
}




  render() {
            return <div id={this.props.tabId} className="tab-item" style={{display: "none"}}>
             <div  className="row " >
                 {(() => {
                  if (this.state.news.length > 7) {

                    return (<>
                        <div className="col-sm-8">
                                        {/*  <!-- news starts--> */}
                                            <div className="news">
                                            <ul className="news__list">
                                                <li className="news__item d_block_res"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news[0].name}>{/* <img src={process.env.REACT_APP_IMAGE+this.state.news0['cover_photo']+'?func=crop&w=230&h=265'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news[0].cover_photo} className="styl4-char-img" width="230px" height="265px"  alt="Loading..." title="Loading..."/></Link>
                                                <div className="l-details news-dtls4">
                                                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news[0]['name']}> {this.state.news[0]['title']}</Link></h3>
                                                    <div> {this.state.content0}...</div>
                                                </div>
                                                </li>
                                                <li className="news__item d_block_res"><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news[1]['name']}>{/* <img src={process.env.REACT_APP_IMAGE+this.state.news1['cover_photo']+'?func=crop&w=230&h=265'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news[1]['cover_photo']} className="styl4-char-img" width="230px" height="265px"  alt="Loading..." title="Loading..."/></Link>
                                                <div className="l-details news-dtls4">
                                                    <h3><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news[1]['name']}>{this.state.news[1]['title']}</Link></h3>
                                                    <div> {this.state.content1}...</div>
                                                </div>
                                                </li>
                                            </ul>
                                            </div>
                                            {/* <!-- news ends--> */}
                                        </div>
                                        <div className="col-sm-8">
                                            <ul className="news__list">
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news2['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news2['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news2['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news2['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news2['name']}><ReactHtml html={this.state.news2['title']}/></Link></div>
                                                </div>
                                            </li>
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news3['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news3['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news3['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news3['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news3['name']}><ReactHtml html={this.state.news3['title']}/></Link></div>
                                                </div>
                                            </li>
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news4['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news4['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news4['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news4['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news4['name']}><ReactHtml html={this.state.news4['title']}/></Link></div>
                                                </div>
                                            </li>
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news5['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news5['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news5['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news5['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news5['name']}><ReactHtml html={this.state.news5['title']}/></Link></div>
                                                </div>
                                            </li>
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news6['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news6['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news6['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news6['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news6['name']}><ReactHtml html={this.state.news6['title']}/></Link></div>
                                                </div>
                                            </li>
                                            <li className="news__item">
                                                <div className="l-thumb">{/* <img src={process.env.REACT_APP_IMAGE+this.state.news7['cover_photo']+'?func=crop&w=130&h=75'}alt="Loading..." title="Loading..." /> */}
                                                <img src={ process.env.REACT_APP_API_URL+this.state.news7['cover_photo']} className="styl9-abhi-img" width="130px" height="75px"  alt="Loading..." title="Loading..."/>
                                                </div>
                                                <div className="l-details news-dtls3">
                                                <div className="l-date"> <Moment format="MMM DD" withTitle>{this.state.news7['start_date']}
                                                </Moment> | <Moment  format="h:MM A"  withTitle>{this.state.news7['start_date']}</Moment></div>
                                                <div><Link to={process.env.PUBLIC_URL +'/news/'+this.state.news7['name']}><ReactHtml html={this.state.news7['title']}/></Link></div>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                    </>)
                  } 
              })()}


        
        
        
                    </div>
                </div>

             }
}
export default Tabitems;