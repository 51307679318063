
import React from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import Fullwidth from '../Components/Fullwidth';



class  Youtube extends React.Component {
  constructor(){
    super();
    this.state = {
      videos: [],
      mainID:''
    }
}
  componentDidMount(){
    axios.get(encodeURI(process.env.REACT_APP_API_URL +'api/method/bm.api.latestVideos'), {
      headers : {'content-type': 'application/json'}
        })
        .then(res=>{
          this.setState({videos:res.data['message']})
          this.setState({mainID:res.data['message'][0].videoId})
        })
        
        .catch(
          err =>{ console.log(err)}
        )
  }
  async selectVideo(e, videoId){
    e.preventDefault();
    if(videoId){
      this.setState({mainID:videoId})
    }
  }

  render() {
    
return <div>
 <div className="row">
          <div className="col-16">
            <div className="video-main-wrapper">
              <div className="video-main">
                <iframe title="Videos" className="video-main__frame" id="vid_frame" src={"https://www.youtube.com/embed/"+ this.state.mainID +"?enablejsapi=1&amp;rel=0&amp;showinfo=0"} webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" async="" width="771" height="434" frameBorder="0"></iframe>
              </div>
              <div className="video-list">
                <div className="video-list__title">
                  <h3>Videos</h3><span className="video-list__count">1 / 10 videos</span>
                </div>
                <div className="video-list__nav has-title">
                  <ol>
                  {this.state.videos.map((itm,k)=>{
                    let dd = '';
                    dd = itm['publishedat'];
                    if(dd){
                      dd = dd.replace('T', ' ').replace('ZT', '')
                    }
                   


                    return <li key={k}><a className="video-list__item" href="/#" onClick={e => this.selectVideo(e, itm['videoId'])} >
                        <div className="l-thumb"><img width="75" alt="" src={itm['thumbnail_url']} /></div>
                        <div className="l-details">
                          <h5>{itm['title']}</h5><span><Moment fromNow>{dd}</Moment></span>
                        </div></a></li>
                    })}
                    
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-16">
            <Fullwidth zone="ZNE006" />
          </div>
        </div>
</div>
}
}
export default Youtube;